<script>
import {getToken} from "@/util/tools";

export default {
    name: "",
    data() {
        return {
            cityList: [],
            currencyType: '', //当前币种
            cType: 'USDT',
            ifLogin: false,
            userInfo: null,
            country_en: "",
        }
    },
    created() {
        this.initToken()
        this.getCurrency()
    },
    computed: {
        member: function () {
            return this.$store.getters.member || localStorage.getItem("MEMBER");
        },
        isLogin: function () {
            return this.$store.getters.isLogin;
        },
    },
    methods: {
        goRoute(path) {
            if(path === this.$route.name) return
            if(path === 'my') this.$router.push({path: path})
            if (this.userInfo && !this.userInfo.jbPassword) {
                // console.log( this.userInfo , this.userInfo.jbPassword )
                if (path === 'wallet') this.$parent.fundPwdPopup = true
                if (path === '/') this.$router.push({path: path})
            } else {
                this.$router.push({path: path})
            }
        },
        // init(){
        //     if(this.member){
        //         console.log( this.member )
        //         this.userInfo = JSON.parse(JSON.stringify(this.member))
        //     }
        //     if(localStorage.getItem('MEMBER') && localStorage.getItem('MEMBER') !== 'null'){
        //         console.log( localStorage.getItem('MEMBER') )
        //         this.userInfo = JSON.parse(localStorage.getItem('MEMBER'))
        //     }
        // },
        gotoLogin() {
            this.$router.push("/login");
        },
        gotoReg() {
            this.$router.push("/MobileRegister");
        },
        initToken() {
            const t = getToken()
            const m = this.member && this.member !== 'null'
            if (t || m) {
                this.ifLogin = true
            } else {
                this.ifLogin = false
            }
        },
        getCurrency() {
            if (this.member && this.member !== 'null') {
                let memberItem
                // console.log( this.member )
                memberItem = typeof this.member === "string" ? JSON.parse(this.member) : this.member
                this.userInfo = memberItem
                this.cType = memberItem.remark
                this.$http.post(this.host + this.api.uc.personalWallet).then(response => {
                    let resp = response.body;
                    this.cityList = []
                    if (resp.code == "0000") {
                        resp.data.forEach(item => {
                            this.cityList.push({
                                amount: item.amount,
                                ccy: item.ccy,
                                id: item.id,
                                uid: item.uid,
                                userNo: item.userNo,
                                value: item.ccy,
                                label: item.amount
                            })
                        });
                    } else {
                        this.$Notice.error({
                            // title: this.$t("common.tip"),
                            title: 'err',
                            desc: resp.mesg
                        });
                    }
                });
            }

        },
        changeType(val) {
            let mber = this.member
            if (typeof mber === 'object') mber = JSON.stringify(mber)
            let params = {
                remark: val,
                userNo: JSON.parse(mber).userNo,
            }
            this.$http.post(this.host + this.api.uc.updatePersonalInfo, params)
                .then(response => {
                    const resp = response.body;
                    if (resp.code == '0000') {
                        // this.member.huioneName = this.huioneName;
                        // this.$store.commit("setMember", this.member);
                        // this.$Message.success(this.$t("uc.finance.xx5"));
                    }
                })
        },
    }
}
</script>

<template>
    <div class="header-box">
        <img src="../assets/logo/logojb1.png" style="width: 100px;" alt="" @click="goRoute('/')">
        <!--币种-->
        <Select @on-change="changeType" v-if="ifLogin" v-model="cType" style="width:160px;float: left">
            <Option v-for="item in cityList" :value="item.value" :key="item.value">{{ item.label }} {{ item.ccy }}
            </Option>
        </Select>
        <div class="l-r-btn" v-else>
            <div class="btn-login btn" @click="gotoLogin">{{ $t('uc.login.login') }}</div>
            <div class="btn-reg btn" @click="gotoReg">{{ $t('common.register') }}</div>
        </div>
        <div v-if="ifLogin" class="wallet" @click="goRoute('wallet')">
            <Icon style="font-size: 24px;color: #fff" type="md-map"/>
        </div>
        <div v-if="ifLogin" @click="goRoute('my')">
            <Icon style="font-size: 24px;color: #fff" type="ios-person"/>
        </div>
        <!--        <div v-if="ifLogin">-->
        <!--            <Icon style="font-size: 22px;color: #fff" type="ios-notifications"/>-->
        <!--        </div>-->
    </div>
</template>

<style scoped lang="scss">
.header-box {
    position: fixed;
    top: 0;
    height: 60px;
    width: 100%;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
    display: flex;
    background: #1A2C38;
    box-shadow: #0003 0 4px 6px -1px, #0000001f 0 2px 4px -1px;
    z-index: 1000;
}

.l-r-btn {
    display: flex;

    .btn-login {
        margin-right: 10px;
        padding: 8px 20px;
        border-radius: 4px;
    }

    .btn {
        color: #fff;
        font-weight: bold;
        font-size: 16px;
    }

    .btn-reg {
        background-color: #1375E1;
        padding: 8px 20px;
        border-radius: 4px;
    }
}

.wallet {
    padding: 10px;
    background-color: #1375E1;
    border-radius: 4px;
}

/deep/ .ivu-select {
    height: 80%;

    .ivu-select-selection {
        height: 100%;
        border: none;
        border-radius: 0;

        & > div {
            height: 100%;
            background-color: #0E212E;
            display: flex;

            justify-content: center;
            align-items: center;

            .ivu-select-selected-value {
                height: 100%;
                color: #fff;
                font-weight: bold;
                display: flex;
                align-items: center;
            }
        }
    }
}
</style>
