<script>
export default {
    // 资金密码弹窗
    name: "",
    props: ['fundPwdPopup'],
    data() {
        return {
            // model: false,
            localFundPwdPopup: this.fundPwdPopup
        }
    },
    methods: {
        close(val) {
        },
        changeMode(val) {
            if (!val) {
                this.$parent.fundPwdPopup = false
            }
        },
        goSetPwd() {
            this.$router.push({name: 'walletSetup'})
        }
    },
    watch: {
        fundPwdPopup(newVal, old) {
            this.localFundPwdPopup = newVal
        }
    },
}
</script>

<template>
    <Modal
        @on-visible-change="changeMode"
        :footer-hide="true"
        v-model="localFundPwdPopup"
        class-name="vertical-center-modal">
        <div class="con">
            <div class="bg">
                <img class="bg-img" src="../assets/common/fund-pwd.png" alt="">
            </div>
            <div class="txt1">让我们设置您的钱包，然后开始游戏吧！</div>
            <!--            <div class="txt2">-->
            <!--                确认您的电邮地址， 然后快速验证您的账户的详细信息以开始使用 Stake。 这将允许您无缝地存入和提取资金。-->
            <!--            </div>-->
            <Button class="btn" type="primary" @click="goSetPwd">前往设置</Button>
        </div>
    </Modal>
</template>

<style scoped lang="scss">
.vertical-center-modal {
    display: flex;
    align-items: center;
    justify-content: center;

    .ivu-modal {
        top: 0;
    }
}


/deep/ .ivu-modal-content {
    background-color: #1A2C38;
}

.con {
    background-color: #1A2C38;
}

.bg {
    width: 100%;

    .bg-img {
        width: 100%;
    }
}

.txt1 {
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    margin: 20px 0;
}

//.txt2{
//    font-size: 14px;
//    color: #bab1d3;
//}

.btn {
    display: block;
    width: 90%;
    margin: auto;
    height: 44px;
    color: #fff;
    font-weight: bold;
}
</style>
